
.p-footer{
  overflow: auto;
  &.dark{
    background: radial-gradient(65% 70% at 58% 45%, #232323, #1a1a1a);
  }
    .transparent{
        &.py-7{
            color: white;
        }
    }
}
@media (min-width:768px) {
    .p-footer{
        font-size: 14px;
        .dark{
            background-color: #333;
            color: #fff;
        }
    }
}
@media (max-width:767px) {
    .p-footer{
        font-size: 12px;
        .dark{
            background-color: #333;
            color: #fff;
        }
    }
}