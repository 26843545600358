.exchange-menu.menu{
    p{margin: 0;}
    width: 300px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-top: 8px;
    .text-8b8b91{
        color: #8b8b91;
    }
    li{
        padding: 0;
    }
}
.exchange-menu.small{
    width: 180px;
}
.nav-title-box.nav-line{
    border-bottom: 1px solid rgba(0, 0, 0, .06);
}
@media (min-width: 768px) {
    .nav-title-box {
        height: 100px;
    }
}
@media (max-width: 767px) {
    .nav-title-box {
        height: 82px;
    }
}