@media (min-width: 768px) {

    .privacy-content{
        padding: 0 25%;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        box-sizing: border-box;
        p{
        margin: 12px 0 !important;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        }
    }

}
@media (max-width: 767px) {
    
    .privacy-content{
        padding: 0 32px;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        box-sizing: border-box;
        p{
        margin: 12px 0 !important;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        }
    }

}