@media (min-width: 768px) {
    .p-drep-page {
        position: relative;

        .drep-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: calc(100vh - 160px);

            .drep-container-box {
                .container-img {
                    width: 30vw;
                }

                .title-num {
                    color: #fbd42c;
                    font-size: 24px;
                }

                .container-title {
                    width: 360px;
                    margin-left: 32px;

                    .ani-title-container {
                        border-top: 1px solid #000;
                        padding-left: 32px;
                        position: relative;
                        height: 10px;
                        overflow: hidden;
                        width: 200px;

                        .ani-bottom-division {
                            position: absolute;
                            left: 20px;
                            height: 5px;
                            width: 60%;
                            background: #000;
                            transition: all .5s ease-in-out;
                        }
                    }

                    &:hover .ani-title-container .ani-bottom-division {
                        width: 100%;
                    }
                }
            }
        }

        .triangle {
            width: 270px;
            position: absolute;
            z-index: 9;
            transition: all 1.2s ease-in-out;
        }

        .triangle-first {
            left: -80px;
            top: -60px;
            transform: translateX(-100%) rotateZ(0deg);
        }

        .triangle-second {
            width: 300px;
            right: -100px;
            bottom: 0px;
            transform: translateX(100%) rotateZ(0deg);

        }

        .triangle-first.page1 {
            opacity: 1;
            transform: translateX(0%) rotateZ(120deg);
        }

        .triangle-second.page1 {
            opacity: 1;
            transform: translateX(0%) rotateZ(0deg) translateY(40%);
        }

        .triangle-first.page1out {
            opacity: 1;
        }

        .triangle-second.page1out {
            opacity: 0;
            transform: translate(100%, -300%) rotateZ(120deg);
        }
    }
}

@media (max-width: 767px) {
    .p-drep-page {
      overflow: auto;
        .drep-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .drep-container-box {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                padding: 32px;

                .container-img {
                    width: 73vw;
                    height: 55vw;
                    order: 1;
                }

                .title-num {
                    color: #fbd42c;
                    font-size: 24px;
                }

                .container-title {
                    font-size: 14px;
                    margin-bottom: 24px;

                    .ani-title-container {
                        border-top: 1px solid #000;
                        padding-left: 32px;
                        position: relative;
                        height: 10px;
                        overflow: hidden;
                        width: 100px;

                        .ani-bottom-division {
                            position: absolute;
                            left: 20px;
                            height: 5px;
                            width: 60%;
                            background: #000;
                            transition: all .5s ease-in-out;
                        }
                    }

                    &:hover .ani-title-container .ani-bottom-division {
                        width: 100%;
                    }
                }
            }
        }
    }
}