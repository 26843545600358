$icon-siez-1: 12px;
$icon-siez-2: 16px;
$icon-siez-3: 20px;
$icon-siez-4: 24px;
$icon-siez-5: 32px;
$icon-siez-6: 48px;
$icon-siez-7: 64px;

.iconfont{
    font-family:"iconfont" !important;
    font-size:16px;font-style:normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
}
.icon {
    width: 1em; height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}

.is_1 {
    font-size: $icon-siez-1;
}
.is_2 {
    font-size: $icon-siez-2;
}
.is_3 {
    font-size: $icon-siez-3;
}
.is_4 {
    font-size: $icon-siez-4;
}
.is_5 {
    font-size: $icon-siez-5;
}
.is_6 {
    font-size: $icon-siez-6;
}
.is_7 {
    font-size: $icon-siez-7;
}