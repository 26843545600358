
// 主题色
$light_main-color: #7863FB;

// 不变白
$white: #FFFFFF;
// 字体颜色
$light_font-color-1: #323041;
$light_font-color-2: rgba(0, 0, 0, 0.6);
$light_font-color-3: rgba(0, 0, 0, 0.4);
$light_font-color-4: rgba(0, 0, 0, 0.3);
// 背景色
$light_background-color-1: #F5F5F5;
$light_background-color-2: #FFFFFF;

// Warning, success, error, failure
$light_hint-color-1: #FFA550;
$light_hint-color-2: #04B78A;
$light_hint-color-3: #FF0000;
$light_hint-color-4: #1890FF;
.light{
    background-color: $light_background-color-2;
    color: $light_font-color-1;
    .primary{
        background-color: $light_main-color;
    }
    .primary_text{
        color: $light_main-color;
    }
    .white_text{
        color: $white;
    }
    .white{
        background-color: $white;
    }

    /**
        背景颜色
    */
    
    // 一级
    .bg_primary{
        background-color: $light_background-color-1 !important;
    }
      // 二级
    .bg_secondary{
        background-color: $light_background-color-2 !important;
    }

    /**
        字体颜色
    */
    // 一级
    .text_primary{
        color: $light_font-color-1 !important;
    }
      // 二级
    .text_secondary{
        color: $light_font-color-2 !important;
    }
      //  三级
    .text_default{
        color: $light_font-color-3;
    }
    //  四级
    .text_low{
        color: $light_font-color-4;
    }

    /**
        提示颜色
    */
    .text_warning{
        color: $light_hint-color-1;
    }
    .text_success{
        color: $light_hint-color-2;
    }
    .text_error{
        color: $light_hint-color-3;
    }
    .text_failure{
        color: $light_hint-color-4;
    }

    .warning_text{
        background-color: $light_hint-color-1;
    }
    .success_text{
        background-color: $light_hint-color-2;
    }
    .error_text{
        background-color: $light_hint-color-3;
    }
    .failure_text{
        background-color: $light_hint-color-4;
    }
}