@media (min-width: 768px) {
    .p-media-detaile{
        .detail-centent{
            max-width: 1200px;
            margin: 0 auto;
            padding-top: 32px;
            .detail-title-img{
                width: 100%;
                height: 600px;
                object-fit: cover;
                border-radius: 4px;
                margin-bottom: 64px;
            }
            .userInfo{
                .user-img{
                    width: 47px;
                    height: 47px;
                    background-color: #C4C4C4;
                    border-radius: 50%;
                }
                >img{
                    width: 47px;
                    height: 47px;
                    border-radius: 50%;
                }
            }
            .subcentent{
                background: #F7F7F7;
                border-radius: 8px;
                .sub-img-box{
                    display: flex;
                    justify-content: flex-end;
                    .sub-img{
                        >img{
                            width: 24px;
                            height: 24px;
                        }
                    }
                    
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .p-media-detaile{
        .detail-centent{
            padding: 32px;
            .detail-title-img{
                width: 100%;
                height: 150px;
                object-fit: cover;
                border-radius: 4px;
                margin-bottom: 32px;
            }
            .userInfo{
                .user-img{
                    width: 47px;
                    height: 47px;
                    background-color: #C4C4C4;
                    border-radius: 50%;
                }
                >img{
                    width: 47px;
                    height: 47px;
                    border-radius: 50%;
                }
            }
            .subcentent{
                background: #F7F7F7;
                border-radius: 8px;
                .sub-img-box{
                    display: flex;
                    margin: 0 -12px;
                    .sub-img{
                        width: 16.6%;
                        >img{
                            width: 24px;
                            height: 24px;
                        }
                    }
                    
                }
            }
        }
    }
}