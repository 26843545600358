

@media (min-width: 768px) {
    .p-media{
        .media-header{
            height: 500px;
            margin-bottom: 32px;
            .nav-title-box{
                position: absolute;
                top: 0;
                z-index: 9;
                width: 100%;
            }
            .swiper1{
                height: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .swiper-slide{
                    width: 100% !important;
                }
            }
        }
        .title-img{
            height: 56px;
        }
        .news-list{
            max-width: 1200px;
            margin: 0 auto;
        }
        .news-more{
            font-size: 14px;
            font-weight: 500;
            padding: 12px 63px;
            background: #F6D353;
            border-radius: 31px;
            cursor: pointer;
        }
        .swiper2{
            height: 400px;
            width: 80%;
            .swiper-wrapper {
                height: 400px;
                width: 500px;
            }

            .swiper-slide {
                height: 100%;
                background-color: rgba(0, 0, 0, 1);
                position: relative;
            }

            video {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                object-fit: fill;
            }
            .shelter {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 222;
            }
              
            .shelter span {
                position: absolute;
                display: inline-block;
                height: 50px;
                width: 50px;
                line-height: 50px;
                text-align: center;
                background-color: rgba(255, 255, 255, 0.4);
                border: 1px solid #fff;
                border-radius: 50%;
                z-index: 888;
                cursor: pointer;
            }
        }
        .coverage{
            flex-grow: 1;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            /*width: 45px;*/
            height: 360px;
            .coverage-url{
                display: flex;
                margin-bottom: 24px;
                align-items: center;
                height: 30%;
                flex-basis: 25%;
                >img{
                    width: 100px;
                }
            }
        }
    }
}
@media (max-width: 767px) {
    .p-media{
        .media-header{
            height: 30vh;
            margin-bottom: 24px;
            .nav-title-box{
                position: absolute;
                top: 0;
                z-index: 9;
                width: 100%;
            }
            .swiper1{
                height: 100%;
                img{
                    width: 100% !important;
                    height: 100%;
                    object-fit: cover;
                }
                .swiper-slide{
                    width: 100% !important;
                }
            }
        }
        .title-img{
            height: 32px;
        }
        .news-list{
            justify-content: space-between;
        }
        .swiper2{
            height:135px;
            width: 100%;
            .swiper-wrapper {
                height: 100%;
                width: 100%;
            }

            .swiper-slide {
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, 1);
                position: relative;
            }
            .swiper-slide-active {
            }
            video {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                object-fit: fill;
            }
            .shelter {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 222;
            }
              
            .shelter span {
                position: absolute;
                display: inline-block;
                height: 50px;
                width: 50px;
                line-height: 50px;
                text-align: center;
                background-color: rgba(255, 255, 255, 0.4);
                border: 1px solid #fff;
                border-radius: 50%;
                z-index: 888;
                cursor: pointer;
            }
        }
        .coverage{
            flex-grow: 1;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            /*width: 45px;*/
            height: 360px;
            .coverage-url{
                display: flex;
                margin-bottom: 24px;
                align-items: center;
                height: 30%;
                flex-basis: 25%;
                >img{
                    width: 60px;
                }
            }
        }
    }
}