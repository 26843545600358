
@media (min-width: 768px) {
    .components-header-back{
        // z-index: 9;
        // position: fixed;
        // top: 0;
        // width: 100%;
        // height: 168px;
        box-shadow: 0px 8px 32px rgba(231, 230, 242, 0.4);
        .header-back-box{
            max-width: 1200px;
            margin: 0 auto;
            height: 68px;
            line-height: 68px;
        }
    }
}

@media (max-width: 767px) {
    .components-header-back{
        // z-index: 9;
        // position: fixed;
        // top: 0;
        // width: 100%;
        // height: 150px;
        box-shadow: 0px 8px 32px rgba(231, 230, 242, 0.4);
        .header-back-box{
            height: 68px;
            line-height: 68px;
            padding: 0 24px;
        }
    }
}