@media (min-width: 768px) {
    .p-resource{
        position: relative;
        padding: 0 150px;
        display: flex;
        flex-direction: column;
        font-family: Avenir-Medium;
        font-weight: 500;
        text-align: left;
        box-sizing: border-box;
        height: 900px;
        .content-wrapper{
            margin: 50px 0 50px;
           .page-title{
               margin-bottom: 90px;
               width: 283px;
               height: 41px;
               font-size: 30px;
               font-weight: 500;
               line-height: 30px;
               text-align: left;
           }
           .page-content{
               margin-bottom: 200px;
               display: flex;
               flex-direction: column;
               .content-box{
                   display: flex;
                  //  margin-right:39% ;
                   justify-content: space-between;
                   margin-bottom: 80px;
                   .content{
                     flex: 1;
                       display: flex;
                       flex-direction: column;
                       flex-wrap: nowrap;
                       justify-content: space-between;
                       height: 98px;
                   }
                   .content-right{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    height: 60px;
                   }
                     
                a{
                font-size: 18px;
                 font-weight: 400;
                 text-decoration:underline ;
                 margin: bottom 20px;
                //  line-height: 30px;
            
                }
                .box{
                    clear: both;
                }
             }
               .main-title{
                   width: 100%;
                   color: #3333;
                   display: flex;
                   align-items: center;
                   span{
                       font-size: 24px;
                       margin-right: 41px;
                       color: #333;
                   }
                   .line{
                       flex: 1;
                       height: 5px;
                       background: #333;
                       

                   }
                  
                   
               }
               .sub-title-container{
                   display: flex;
                   justify-content: space-between;
                .sub-title{
                    flex: 1;
                    width: 155px;
                    height: 27px;
                    font-size: 20px;
                    font-weight: 500;
                    color: #333;
                    line-height: 27px;
                    text-align: left;
                    font-family: Avenir-Medium;
                    margin-top: 51px;
                    margin-bottom: 41px;
                }
               
                
            }
            .content{
                a{
                font-size: 18px;
             font-weight: 400;
             text-decoration:underline ;
             margin: bottom 20px;
                }
            }
           }
        }
    }
}


@media (max-width: 767px) {
    .p-resource{
        position: relative;
        padding: 0 10%;
        display: flex;
        flex-direction: column;
        font-family: Avenir-Medium;
        font-weight: 500;
        text-align: left;
        box-sizing: border-box;
        height: 900px;
        .content-wrapper{
            margin: 50px 0 50px;
           .page-title{
               margin-bottom: 90px;
               width: 283px;
               height: 41px;
               font-size: 30px;
               font-weight: 500;
               line-height: 30px;
               text-align: left;
           }
           .page-content{
               margin-bottom: 200px;
               display: flex;
               flex-direction: column;
               .content-box{
                   display: flex;
                //    margin-right:39% ;
                   justify-content: space-between;
                   margin-bottom: 80px;
                   .content{
                     flex: 1;
                       display: flex;
                       flex-direction: column;
                       flex-wrap: nowrap;
                       justify-content: space-between;
                       height: 98px;
                       a{
                        font-size: 10px;
                         font-weight: 400;
                         text-decoration:underline ;
                         margin: bottom 20px;
                        //  line-height: 30px;
                    
                        }
                   }
                   .content-right{
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    height: 60px;
                    a{
                        font-size: 10px;
                         font-weight: 400;
                         text-decoration:underline ;
                         margin: bottom 20px;
                         line-height: 30px;
                    
                        }
                   }
                     
                
             }
               .main-title{
                   width: 100%;
                   color: #3333;
                   display: flex;
                   align-items: center;
                   span{
                       font-size: 24px;
                       margin-right: 41px;
                       color: #333;
                   }
                   .line{
                       flex: 1;
                       height: 5px;
                       background: #333;
                    //    width: 10px;

                   }
                  
                   
               }
               .sub-title-container{
                   display: flex;
                   justify-content: space-around;
                .sub-title{
                    flex: 1;
                    width: 155px;
                    height: 27px;
                    font-size: 20px;
                    font-weight: 500;
                    color: #333;
                    line-height: 27px;
                    text-align: left;
                    font-family: Avenir-Medium;
                    margin-top: 51px;
                    margin-bottom: 41px;
                }
               
                
            }
            .content{
                a{
                font-size: 10px;
             font-weight: 400;
             text-decoration:underline ;
             margin: bottom 20px;
                }
            }
           }
        }
    }
}