



$spacing-0: 0;
$spacing-1: 2px;
$spacing-2: 4px;
$spacing-3: 8px;
$spacing-4: 12px;
$spacing-5: 16px;
$spacing-6: 12px;
$spacing-7: 32px;
$spacing-8: 48px;
$spacing-9: 64px;
$spacing-10: 128px;
$spacing-11: 256px;

$side-1: 24px;
$title-1: 14px;
    
$font-siez-1: 12px;
$font-siez-2: 14px;
$font-siez-3: 18px;
$font-siez-4: 24px;
$font-siez-5: 32px;
$font-siez-6: 48px;
$font-siez-7: 64px;
$font-siez-8: 16px;
/* font-size */
.fs-1 {
	font-size: $font-siez-1 !important;
    line-height: 14px;
}
.fs-2 {
	font-size: $font-siez-2 !important;
}
.fs-3 {
	font-size: $font-siez-3 !important;
}
.fs-4 {
	font-size: $font-siez-4 !important;
}
.fs-5 {
	font-size: $font-siez-5 !important;
}
.fs-6 {
	font-size: $font-siez-6 !important;
}
.fs-7 {
	font-size: $font-siez-7 !important;
}

.fs-8 {
	font-size: $font-siez-8 !important;
}


//  ------  end-------------


@media (min-width: 768px) {
    .title{
        font-size: 48px;
    }
}
@media (max-width: 768px) {
    .title{
        font-size: 18px;
    }
}

.side-a{
    padding: $side-1;
}
.side-x{
    padding-left: $side-1;
    padding-right: $side-1;
}
.side-y{
    padding-top: $side-1;
    padding-bottom: $side-1;
}

.pa-0 {
    padding: $spacing-0 !important;
}

.pl-0 {
    padding-left: $spacing-0 !important;
}

.pr-0 {
    padding-right: $spacing-0 !important;
}

.pt-0 {
    padding-top: $spacing-0 !important;
}

.pb-0 {
    padding-bottom: $spacing-0 !important;
}

.px-0 {
    padding-left: $spacing-0 !important;
    padding-right: $spacing-0 !important;
}

.py-0 {
    padding-top: $spacing-0 !important;
    padding-bottom: $spacing-0 !important;
}

.pa-1 {
    padding: $spacing-1 !important;
}

.pl-1 {
    padding-left: $spacing-1 !important;
}

.pr-1 {
    padding-right: $spacing-1 !important;
}

.pt-1 {
    padding-top: $spacing-1 !important;
}

.pb-1 {
    padding-bottom: $spacing-1 !important;
}

.px-1 {
    padding-left: $spacing-1 !important;
    padding-right: $spacing-1 !important;
}

.py-1 {
    padding-top: $spacing-1 !important;
    padding-bottom: $spacing-1 !important;
}

.pa-2 {
    padding: $spacing-2 !important;
}

.pl-2 {
    padding-left: $spacing-2 !important;
}

.pr-2 {
    padding-right: $spacing-2 !important;
}

.pt-2 {
    padding-top: $spacing-2 !important;
}

.pb-2 {
    padding-bottom: $spacing-2 !important;
}

.px-2 {
    padding-left: $spacing-2 !important;
    padding-right: $spacing-2 !important;
}

.py-2 {
    padding-top: $spacing-2 !important;
    padding-bottom: $spacing-2 !important;
}

.pa-3 {
    padding: $spacing-3 !important;
}

.pl-3 {
    padding-left: $spacing-3 !important;
}

.pr-3 {
    padding-right: $spacing-3 !important;
}

.pt-3 {
    padding-top: $spacing-3 !important;
}

.pb-3 {
    padding-bottom: $spacing-3 !important;
}

.px-3 {
    padding-left: $spacing-3 !important;
    padding-right: $spacing-3 !important;
}

.py-3 {
    padding-top: $spacing-3 !important;
    padding-bottom: $spacing-3 !important;
}

.pa-4 {
    padding: $spacing-4 !important;
}

.pl-4 {
    padding-left: $spacing-4 !important;
}

.pr-4 {
    padding-right: $spacing-4 !important;
}

.pt-4 {
    padding-top: $spacing-4 !important;
}

.pb-4 {
    padding-bottom: $spacing-4 !important;
}

.px-4 {
    padding-left: $spacing-4 !important;
    padding-right: $spacing-4 !important;
}

.py-4 {
    padding-top: $spacing-4 !important;
    padding-bottom: $spacing-4 !important;
}

.pa-5 {
    padding: $spacing-5 !important;
}

.pl-5 {
    padding-left: $spacing-5 !important;
}

.pr-5 {
    padding-right: $spacing-5 !important;
}

.pt-5 {
    padding-top: $spacing-5 !important;
}

.pb-5 {
    padding-bottom: $spacing-5 !important;
}

.px-5 {
    padding-left: $spacing-5 !important;
    padding-right: $spacing-5 !important;
}

.py-5 {
    padding-top: $spacing-5 !important;
    padding-bottom: $spacing-5 !important;
}

.pa-6 {
    padding: $spacing-6 !important;
}

.pl-6 {
    padding-left: $spacing-6 !important;
}

.pr-6 {
    padding-right: $spacing-6 !important;
}

.pt-6 {
    padding-top: $spacing-6 !important;
}

.pb-6 {
    padding-bottom: $spacing-6 !important;
}

.px-6 {
    padding-left: $spacing-6 !important;
    padding-right: $spacing-6 !important;
}

.py-6 {
    padding-top: $spacing-6 !important;
    padding-bottom: $spacing-6 !important;
}

.pa-7 {
    padding: $spacing-7 !important;
}

.pl-7 {
    padding-left: $spacing-7 !important;
}

.pr-7 {
    padding-right: $spacing-7 !important;
}

.pt-7 {
    padding-top: $spacing-7 !important;
}

.pb-7 {
    padding-bottom: $spacing-7 !important;
}

.px-7 {
    padding-left: $spacing-7 !important;
    padding-right: $spacing-7 !important;
}

.py-7 {
    padding-top: $spacing-7 !important;
    padding-bottom: $spacing-7 !important;
}

.pa-8 {
    padding: $spacing-8 !important;
}

.pl-8 {
    padding-left: $spacing-8 !important;
}

.pr-8 {
    padding-right: $spacing-8 !important;
}

.pt-8 {
    padding-top: $spacing-8 !important;
}

.pb-8 {
    padding-bottom: $spacing-8 !important;
}

.px-8 {
    padding-left: $spacing-8 !important;
    padding-right: $spacing-8 !important;
}

.py-8 {
    padding-top: $spacing-8 !important;
    padding-bottom: $spacing-8 !important;
}

.pa-9 {
    padding: $spacing-9 !important;
}

.pl-9 {
    padding-left: $spacing-9 !important;
}

.pr-9 {
    padding-right: $spacing-9 !important;
}

.pt-9 {
    padding-top: $spacing-9 !important;
}

.pb-9 {
    padding-bottom: $spacing-9 !important;
}

.px-9 {
    padding-left: $spacing-9 !important;
    padding-right: $spacing-9 !important;
}

.py-9 {
    padding-top: $spacing-9 !important;
    padding-bottom: $spacing-9 !important;
}

.pa-10 {
    padding: $spacing-10 !important;
}

.pl-10 {
    padding-left: $spacing-10 !important;
}

.pr-10 {
    padding-right: $spacing-10 !important;
}

.pt-10 {
    padding-top: $spacing-10 !important;
}

.pb-10 {
    padding-bottom: $spacing-10 !important;
}

.px-10 {
    padding-left: $spacing-10 !important;
    padding-right: $spacing-10 !important;
}

.py-10 {
    padding-top: $spacing-10 !important;
    padding-bottom: $spacing-10 !important;
}

.pa-11 {
    padding: $spacing-11 !important;
}

.pl-11 {
    padding-left: $spacing-11 !important;
}

.pr-11 {
    padding-right: $spacing-11 !important;
}

.pt-11 {
    padding-top: $spacing-11 !important;
}

.pb-11 {
    padding-bottom: $spacing-11 !important;
}

.px-11 {
    padding-left: $spacing-11 !important;
    padding-right: $spacing-11 !important;
}

.py-11 {
    padding-top: $spacing-11 !important;
    padding-bottom: $spacing-11 !important;
}


.m-a-x {
    margin: 0 auto !important;
}

.ma-0 {
    margin: $spacing-0 !important;
}

.ml-0 {
    margin-left: $spacing-0 !important;
}

.mr-0 {
    margin-right: $spacing-0 !important;
}

.mt-0 {
    margin-top: $spacing-0 !important;
}

.mb-0 {
    margin-bottom: $spacing-0 !important;
}

.mx-0 {
    margin-left: $spacing-0 !important;
    margin-right: $spacing-0 !important;
}

.my-0 {
    margin-top: $spacing-0 !important;
    margin-bottom: $spacing-0 !important;
}

.ma-1 {
    margin: $spacing-1 !important;
}

.ml-1 {
    margin-left: $spacing-1 !important;
}

.mr-1 {
    margin-right: $spacing-1 !important;
}

.mt-1 {
    margin-top: $spacing-1 !important;
}

.mb-1 {
    margin-bottom: $spacing-1 !important;
}

.mx-1 {
    margin-left: $spacing-1 !important;
    margin-right: $spacing-1 !important;
}

.my-1 {
    margin-top: $spacing-1 !important;
    margin-bottom: $spacing-1 !important;
}

.ma-2 {
    margin: $spacing-2 !important;
}

.ml-2 {
    margin-left: $spacing-2 !important;
}

.mr-2 {
    margin-right: $spacing-2 !important;
}

.mt-2 {
    margin-top: $spacing-2 !important;
}

.mb-2 {
    margin-bottom: $spacing-2 !important;
}

.mx-2 {
    margin-left: $spacing-2 !important;
    margin-right: $spacing-2 !important;
}

.my-2 {
    margin-top: $spacing-2 !important;
    margin-bottom: $spacing-2 !important;
}

.ma-3 {
    margin: $spacing-3 !important;
}

.ml-3 {
    margin-left: $spacing-3 !important;
}

.mr-3 {
    margin-right: $spacing-3 !important;
}

.mt-3 {
    margin-top: $spacing-3 !important;
}

.mb-3 {
    margin-bottom: $spacing-3 !important;
}

.mx-3 {
    margin-left: $spacing-3 !important;
    margin-right: $spacing-3 !important;
}

.my-3 {
    margin-top: $spacing-3 !important;
    margin-bottom: $spacing-3 !important;
}

.ma-4 {
    margin: $spacing-4 !important;
}

.ml-4 {
    margin-left: $spacing-4 !important;
}

.mr-4 {
    margin-right: $spacing-4 !important;
}

.mt-4 {
    margin-top: $spacing-4 !important;
}

.mb-4 {
    margin-bottom: $spacing-4 !important;
}

.mx-4 {
    margin-left: $spacing-4 !important;
    margin-right: $spacing-4 !important;
}

.my-4 {
    margin-top: $spacing-4 !important;
    margin-bottom: $spacing-4 !important;
}

.ma-5 {
    margin: $spacing-5 !important;
}

.ml-5 {
    margin-left: $spacing-5 !important;
}

.mr-5 {
    margin-right: $spacing-5 !important;
}

.mt-5 {
    margin-top: $spacing-5 !important;
}

.mb-5 {
    margin-bottom: $spacing-5 !important;
}

.mx-5 {
    margin-left: $spacing-5 !important;
    margin-right: $spacing-5 !important;
}

.my-5 {
    margin-top: $spacing-5 !important;
    margin-bottom: $spacing-5 !important;
}

.ma-6 {
    margin: $spacing-6 !important;
}

.ml-6 {
    margin-left: $spacing-6 !important;
}

.mr-6 {
    margin-right: $spacing-6 !important;
}

.mt-6 {
    margin-top: $spacing-6 !important;
    font-size: 12px;
}

.mb-6 {
    margin-bottom: $spacing-6 !important;
}

.mx-6 {
    margin-left: $spacing-6 !important;
    margin-right: $spacing-6 !important;
}

.my-6 {
    margin-top: $spacing-6 !important;
    margin-bottom: $spacing-6 !important;
}


.ma-7 {
    margin: $spacing-7 !important;
}

.ml-7 {
    margin-left: $spacing-7 !important;
}

.mr-7 {
    margin-right: $spacing-7 !important;
}

.mt-7 {
    margin-top: $spacing-7 !important;
}

.mb-7 {
    margin-bottom: $spacing-7 !important;
}

.mx-7 {
    margin-left: $spacing-7 !important;
    margin-right: $spacing-7 !important;
}

.my-7 {
    margin-top: $spacing-7 !important;
    margin-bottom: $spacing-7 !important;
}

.ma-8 {
    margin: $spacing-8 !important;
}

.ml-8 {
    margin-left: $spacing-8 !important;
}

.mr-8 {
    margin-right: $spacing-8 !important;
}

.mt-8 {
    margin-top: $spacing-8 !important;
}

.mb-8 {
    margin-bottom: $spacing-8 !important;
}

.mx-8 {
    margin-left: $spacing-8 !important;
    margin-right: $spacing-8 !important;
}

.my-8 {
    margin-top: $spacing-8 !important;
    margin-bottom: $spacing-8 !important;
}

.ma-9 {
    margin: $spacing-9 !important;
}

.ml-9 {
    margin-left: $spacing-9 !important;
}

.mr-9 {
    margin-right: $spacing-9 !important;
}

.mt-9 {
    margin-top: $spacing-9 !important;
}

.mb-9 {
    margin-bottom: $spacing-9 !important;
}

.mx-9 {
    margin-left: $spacing-9 !important;
    margin-right: $spacing-9 !important;
}

.my-9 {
    margin-top: $spacing-9 !important;
    margin-bottom: $spacing-9 !important;
}

.ma-10 {
    margin: $spacing-10 !important;
}

.ml-10 {
    margin-left: $spacing-10 !important;
}

.mr-10 {
    margin-right: $spacing-10 !important;
}

.mt-10 {
    margin-top: $spacing-10 !important;
}

.mb-10 {
    margin-bottom: $spacing-10 !important;
}

.mx-10 {
    margin-left: $spacing-10 !important;
    margin-right: $spacing-10 !important;
}

.my-10 {
    margin-top: $spacing-10 !important;
    margin-bottom: $spacing-10 !important;
}

.ma-11 {
    margin: $spacing-11 !important;
}

.ml-11 {
    margin-left: $spacing-11 !important;
}

.mr-11 {
    margin-right: $spacing-11 !important;
}

.mt-11 {
    margin-top: $spacing-11 !important;
}

.mb-11 {
    margin-bottom: $spacing-11 !important;
}

.mx-11 {
    margin-left: $spacing-11 !important;
    margin-right: $spacing-11 !important;
}

.my-11 {
    margin-top: $spacing-11 !important;
    margin-bottom: $spacing-11 !important;
}
