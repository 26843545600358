.dark {
  .footer-content {
    border-top: 1px solid rgba(255, 255, 255, 0) !important;
  }
}

.footer-container {
  // height: 300px;
  color: white;
  // max-width: 1300px;
  padding: 40px 32px 0 32px;
  margin: auto 0;
  background-color: rgba(245, 222, 179, 0);

  .footer-content {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    padding-top: 30px;
    width: 70%;
    margin: 0 auto;
    // height: 100%;
    display: flex;
    position: relative;

    .left {
      height: 100%;
      width: 50%;
      padding-right: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      // align-items: center;
      // background-color: #333;
      .left-list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 20px;

        // gap: 24px;
        p {
          display: block;
          width: 100%;
          // height: 18px;
          font-size: 18px;
          font-weight: 600;
          padding-bottom: 24px;
        }

        .gap {
          margin-top: 10px;
          height: 12px;
          width: 1px;
          background-color: rgba(255, 255, 255, 0.12);
        }

        a {
          font-weight: 600;
          color: inherit;
          display: flex;
          align-items: center;
          // padding-right:calc(50% - 218px);
          // border-right: 1px solid rgba(255, 255, 255, 0.12);
          font-size: 14px;

          &:last-child {
            border-right: 0;
            padding-right: 0;
          }

          img {
            width: 25px;
            margin-right: 10px;
          }
        }
      }

      .left-Info {
        width: 100%;
        height: 90px;
        padding: 0px 0px 16px 0px;
        margin: 0 0 40px 0px;
        border-top: 1px solid rgba(255, 255, 255, 0.12);

        .bar {
          height: 100%;
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;

          .logo {
            display: flex;
            align-items: center;
            color: inherit;

            img {
              display: block;
              height: 50%;
              margin-right: 5px;
            }

            p {
              display: block;
              font-size: 18px;
            }

            .powered {
              opacity: 0.5;
              text-decoration: underline;
            }
          }

          .bar-chart-container {
            display: flex;
            align-items: center;

            .chart {
              margin-top: -2px;
              margin-right: 10px;
              width: 80px;
              height: 70%;
            }

            .market {
              margin-right: 10px;

              .current {
                font-size: 16px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .right {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: right;
      flex-wrap: wrap;

      // background-color: aqua;
      .right-list {

        // width: 150px;
        p {
          width: 100%;
          height: 35px;
          font-size: 14px;
          font-weight: 600;
        }

        a {
          opacity: 0.5;
          color: inherit;
          display: block;
          align-items: center;
          margin-bottom: 4px;
          font-weight: 500px;
          font-size: 14px
        }
      }
    }
  }
}

@media screen and (max-width:1700.1px) {
  .footer-container {
    .footer-content {
      .left {
        .left-list {
          a {
            // padding-right:calc(50% - 198px);
          }
        }
      }
    }
  }
}

@media screen and (max-width:1300.1px) {
  .footer-container {
    .footer-content {
      padding-top: 120px;

      .left {
        width: 150px;
        height: auto;

        .left-list {
          width: 150px;
          flex-direction: column;

          .gap {
            display: none;
          }
        }

        .left-Info {
          width: 468px;
          margin: 0 0 0px 0px;
          position: absolute;
          left: calc(50% - 234px);
          top: 10px;
          border-top: 0;
        }
      }
    }
  }
}

@media (max-height: 817px) {
  .footer-container {
    .footer-content {
      //  min-height: 20vh;
    }
  }
}

@media screen and (max-width:644.1px) {
  .footer-container {
    padding: 0px 32px 0 32px;

    .footer-content {
      width: 100%;
      // overflow: scroll;
      // overflow-x:hidden;
      flex-wrap: wrap;
      padding-top: 20px;

      .left {
        width: 100%;
        padding: 0;

        .left-list {
          width: 100%;
          flex-direction: row;
          margin: 0 auto 20px auto;

          a {
            padding-right: 0;
            border-right: 2px solid rgba(255, 255, 255, 0);
          }
        }

        .left-Info {
          height: 180px;
          width: 100%;
          // border-top: 1px solid rgba(255, 255, 255, 0.215);
          position: inherit;
          margin-bottom: -20px;

          .bar {
            flex-wrap: wrap;

            .logo {
              width: 100%;
              border-top: 1px solid rgba(255, 255, 255, 0.12);
            }

            .bar-chart-container {
              height: 60px;
              width: 100%;
              border: 1px solid rgba(255, 255, 255, 0.12);
              margin: 0;
              justify-content: space-between;
              border-radius: 10px;

              .chart {
                margin-left: 10px;
              }
            }
          }
        }
      }

      .right {
        padding-left: 0;
        justify-content: left;
      }
    }
  }
}

@media (max-width: 768px) {
  .footer-container {
      .icon_box {
        .telegram,
        .kakaotalk,
        .weChat,
        .medium,
        .twitter {
          width: 24px;
          height: 24px;
          margin-right: 12px;
          cursor: pointer;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 50%;
        }

        .telegram {
          background-image: url("../../../style/images/drep_img/telegramDefault.svg");

          &:hover {
            background-image: url("../../../style/images/drep_img/telegram.svg");
          }
        }

        .medium {
          background-image: url("../../../style/images/drep_img/mediumDefault.png");

          &:hover {
            background-image: url('../../../style/images/drep_img/medium.png');
          }
        }

        .twitter {
          background-image: url("../../../style/images/drep_img/twitterDefault.svg");

          &:hover {
            background-image: url('../../../style/images/drep_img/twitter.svg');
          }
        }
        
      }
      
    }

}

// @media screen and (max-width:580.1px) {
//   .footer-container{
//     .footer-content{
//       .right{
//         .right-list{
//           a{
//             // display: none;
//           }
//         }
//         .right-list:hover{
//           a{
//             // display: block;
//           }
//         }
//       }
//     }
//   }
// }