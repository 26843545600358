@media (min-width: 768px) {
    .p-media-list{
        
        .media-list{
            max-width: 1200px;
            margin: 0 auto;
            padding-top: 32px;
            .media-swiper{
                height: 650px;
                margin-bottom: 32px;
                border-radius: 4px;
                .media-list-swiper{
                    height: 100%;
                    .swiper-slide{
                        width: 100% !important;
                        >img{
                            width: 100%;
                            height: calc(100% - 37px);
                            border-radius: 4px;
                            object-fit: cover;
                        }
                    }
                    .swiper-pagination-bullets{
                        z-index: 99;
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        bottom: 0;
                    }
                    .swiper-pagination-bullet{
                        height: 5px;
                        width: 38px;
                        border-radius: 0;
                        
                    }
                    .swiper-pagination-bullet-active{
                        background-color: #F6D353;
                    }
                    
                }
               
            }
            .media-list-item-box{
                margin: 0 -12px;
            }
        }
    }
}

@media (max-width: 767px) {
    .p-media-list{
        
        .media-list{
            padding: 32px;
            .media-swiper{
                height: 159px;
                margin-bottom: 32px;
                border-radius: 4px;
                .media-list-swiper{
                    height: 100%;
                    .swiper-slide{
                        width: 100% !important;
                        >img{
                            width: 100%;
                            height: calc(100% - 37px);
                            border-radius: 4px;
                            object-fit: cover;
                        }
                    }
                    .swiper-pagination-bullets{
                        z-index: 99;
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        bottom: 0;
                    }
                    .swiper-pagination-bullet{
                        height: 5px;
                        width: 38px;
                        border-radius: 0;
                        
                    }
                    .swiper-pagination-bullet-active{
                        background-color: #F6D353;
                    }
                    
                }
               
            }
            .media-list-item-box{
                margin: 0 -12px;
            }
        }
    }
}