@media (min-width: 768px) {
    .news-list-item-box {
        width: 25%;
        padding: 0 12px;

        .news-list-item {

            // margin-right: 32px;
            height: 304px;
            // border: 1px solid #e2e2e2;
            margin-bottom: 24px;

            >img {
                width: 100%;
                height: 152px;
                object-fit: cover;
                border-radius: 4px;
            }

            >p {
                word-break: break-all;
            }
        }
    }

}

@media (max-width: 767px) {
    .news-list-item-box {
        width: 100%;
        // padding: 0 12px;

        .news-list-item {
            box-sizing: border-box;
            height: 290px;
            // border: 1px solid #e2e2e2;
            margin-bottom: 24px;

            >img {
                width: 100%;
                height: 152px;
                object-fit: cover;
                border-radius: 4px;
            }
            >p {
                word-break: break-all;
            }
        }
    }

}

@media (min-width: 768px) and (max-width: 900px) {
    .news-list-item-box {
        width: 33.3%;
        padding: 0 12px;
    }
}