
@media (max-width: 768px){
    .p-network{
        overflow: scroll;
        overflow-x: hidden;
    }
}
@media (min-width: 768px) {
    .p-network {
        background: radial-gradient(65% 70% at 58% 45%, #232323, #1a1a1a);
        height: 100%;
        position: relative;

        .network-title {
            width: 100px;
        }

        .network-list {
            // height: 30vw;
            // overflow: scroll;
            // overflow-x: hidden;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 70%;
            margin: 0 auto;
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            >img {
                width: 100%;
                // height: 94px;
                opacity: 0.8;
                cursor: pointer;
                &:hover{
                    opacity: 1;
                }
            }
            
        }

        .p-footer {
            // position: absolute;
            // bottom: 0;
            // width: 100%;

            .dark {
                background: radial-gradient(65% 70% at 58% 45%, #232323, #1a1a1a);
            }
        }
    }
}
@media screen and (max-width:1050.1px){
    .p-network{
        .network-list{
            grid-template-columns: repeat(4, minmax(0, 1fr));
            
            >img {
                width: 100%;
            }
        }
    }
}
@media (max-width: 767px) {
    .p-network {
        background: radial-gradient(65% 70% at 58% 45%, #232323, #1a1a1a);
        height: 100%;
        position: relative;
        .footer-img{
            display: none;
        }
        .network-title {
            width: 60px;
        }

        .network-list {
            // height: 80vw;
            // max-height: 42vh;
            // min-height: 30vh;
            // overflow: scroll;
            // overflow-x: hidden;
            width: 90%;
            margin: 0 auto;
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));

            >img {
                width: 100%;
                // height: 60px;
            }
        }

        .p-footer {
            // position: absolute;
            // bottom: 0;
            width: 100%;

            .dark {
                background: radial-gradient(65% 70% at 58% 45%, #232323, #1a1a1a);
            }
        }
    }
}