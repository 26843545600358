@import '~antd/dist/antd.css';

html,body,div,img,p{
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
b,
strong {
    font-weight: normal;
}

p {
    margin: 0 !important;
}

h1,
h2,
h3,
h4,
b,
strong {
    font-weight: normal;
}
li{
    list-style: none;
}
i {
    font-style: normal;
}

a {
    text-decoration: none;
}

.page{
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
}

.flex {
    display: flex;
}

.align-center {
    align-items: center;

}

.column {
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.justify-end {
    justify-content: flex-end;
}

.flex-warp {
    flex-wrap: wrap;
}

.flex-1 {
    flex: 1;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.fw-b{
    font-weight: 700;
}
.fw-m{
    font-weight: 500;
}



.p-relative {
    position: relative;
}

.p-absolute {
    position: absolute;
    top: 0;
}

.p-fixed {
    position: fixed;
}

.p-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cursor {
    cursor: pointer;
}

.inline-block {
    display: inline-block !important;
}

.block {
    display: block;
}

.visibility {
    visibility: hidden;
}

.display-none {
    display: none;
}

.w100 {
    width: 100%;
}

.h100 {
    height: 100%;
}

.w80 {
    width: 80%;
}

.w50 {
    width: 50%;
}

.w33 {
    width: 33%;
}

.w30 {
    width: 30%;
}
.t-es-3{
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.t-es-2{
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
  
.t-es-1{
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}
.overflow-hidden{
    overflow: hidden;
}
.white-space-nowrap {
  white-space: nowrap;
}
.transform{
    transform: translateX(0) !important;
}
.animation-name{
    animation-name: breakIn;
    transform: translateX(-300%);
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-delay: 0.5s
  }

.footer-img{
    position: absolute;
    bottom: 64px;
    left: 32px;
    width: 13px;
}

.hide{
    display: none;
}

@media (min-width: 768px) {
    .l-hide{
        display: none;
    }
}

@media (max-width: 767px) {
    .s-hide{
        display: none;
    }
    .footer-img{
      position: absolute;
      bottom: 64px;
      left: 12px;
      width: 13px;
  }
}

@keyframes wordAnimation {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
  
    49% {
      opacity: 0;
      transform: translateY(50%);
    }
  
    50% {
      opacity: 0;
      transform: translateY(-100%);
    }
  
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes arrowToDown {
    0% {
      transform: rotateZ(90deg) translateY(-40%) translateX(0%);
    }
  
    50% {
      transform: rotateZ(90deg) translateY(-40%) translateX(-50%);
    }
  
    100% {
      transform: rotateZ(90deg) translateY(-40%) translateX(0%);
    }
  }

  @keyframes breakIn {
    0% {
      transform: translateX(-100%);
    }
  
    100% {
      transform: translateX(0%);
    }
  }