.moudle_navBtn{
    .dark_lang{
        color: rgba(255, 255, 255, .3);
    }
    .c-navbtn{
        .c-navbtn-box {
            &:hover{
                .c-navbtn-bg{
                    -webkit-transition: all 1s ease;
                    -moz-transition: all 1s ease;
                    -ms-transition: all 1s ease;
                    -o-transition: all 1s ease;
                    transition: all 1s ease;
                    background-color: #fca311 ;
                }
            }
        }
    }
}   

@media (min-width: 768px) {
    .nav-title-box{
        padding: 32px;
    }
    .ant-drawer-content-wrapper{
        height: 100vh !important;
    }
        .p-mune{
            padding: 8px;
            .pc-box{
                max-width: 1200px;
                margin: 0 auto;
            }
            .logo{
                height: 32px;
                width: 32px;
            }
            .close{
                font-size: 32px;
                font-weight: 300;
            }
            .spot {
                transition: left 0.5s ease-in-out;
                // left: 44px;
                // top: 65%;
                // position: absolute;
                height: 10px;
                width: 10px;
                margin: 0 auto;
                border-radius: 50%;
                background: black;
                animation: moveAround 0.5s ease-in-out alternate-reverse infinite;
            }
              
            @keyframes moveAround {
                0% {
                  transform: translateX(-100%);
                }
              
                100% {
                  transform: translateX(100%);
                }
            }
            .footer-box{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
            }
            .telegram, .kakaotalk, .weChat, .medium, .twitter{
                width: 35px ;
                height: 35px ;
                margin-right: 32px;
                cursor: pointer;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 50%;
            }
            .telegram{
                background-image: url("../../../style/images/drep_img/telegramDefault.svg");
                &:hover{
                    background-image: url("../../../style/images/drep_img/telegram.svg");
                }
            }
            .kakaotalk{
                background-image: url("../../../style/images/drep_img/kakaotalkDefault.svg");
                &:hover {
                    background-image: url('../../../style/images/drep_img/kakaotalk.svg');
                }
            }
            .weChat{
                background-image: url("../../../style/images/drep_img/weChatDefault.png");
                position: relative;
                &:hover {
                    background-image: url('../../../style/images/drep_img/weChat.png');
                    &:before {
                        content: '';
                        width: 175px;
                        height: 175px;
                        /*border: 1px solid black;*/
                        position: absolute;
                        top: 0;
                        left: 0;
                        transform: translate(calc(-50% + 16px), -92%);
                        background-image: url('../../../style/images/drep_img/weChat-hover.png');
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        opacity: 0.7;
                      }
                }
            }
            .medium{
                background-image: url("../../../style/images/drep_img/mediumDefault.png");
                &:hover {
                    background-image: url('../../../style/images/drep_img/medium.png');
                }
            }
            .twitter{
                background-image: url("../../../style/images/drep_img/twitterDefault.svg");
                &:hover {
                    background-image: url('../../../style/images/drep_img/twitter.svg');
                }
            }
            .canvas-wrapper {
                z-index: -99;
                /*overflow: hidden;*/
                width: 100%;
                position: fixed;
                left: 0;
            }
        }
}

@media (max-width: 767px) {
    .nav-title-box{
        padding: 24px 32px;
    }
        .p-mune{
            color: #323041;
            .logo{
                height: 24px;
                width: 24px;
            }
            .close{
                font-size: 24px;
                font-weight: 300;
            }
        }

    .mb-exchange{
        .text-8b8b91{
            color: #8b8b91;
        }
        .mb-item{
            &:hover{
                background: #FBFBFB;
            }
        }
        img.rotate{
            transition: all .3s;
            transform: rotate(-180deg);
        }
    }
}
