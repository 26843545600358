@media (min-width: 768px) {
    .p-solutions-page {
        .solutions-center-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: calc(100vh - 160px);
            padding: 0 32px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .solutions-center {
                display: flex;

                .img-box {
                    box-shadow: 2px 1px 25px 1px rgba(0,0,0,0.09);
                    border-radius: 17px;
                    position: relative;
                    width: 500px;
                    
                    >span {
                        display: inline-block;
                        font-size: 30px;
                        font-weight: 700;
                        color: #838383;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        padding: 0 0 32px 32px;

                        &:hover {
                            color: #fbd42c;
                        }
                    }

                    >img {
                        width: 100%;
                    }
                }

                .solutions-title {
                    position: relative;
                    margin-right: 24px;
                    .container-title {
                        width: 342px;

                        .title-num {
                            color: #fbd42c;
                            font-size: 24px;
                        }

                        .ani-title-container {
                            border-top: 1px solid #000;
                            padding-left: 32px;
                            position: relative;
                            height: 10px;
                            overflow: hidden;
                            width: 180px;

                            .ani-bottom-division {
                                position: absolute;
                                left: 20px;
                                height: 5px;
                                width: 60%;
                                background: #000;
                                transition: all .5s ease-in-out;
                            }
                        }

                        &:hover .ani-title-container .ani-bottom-division {
                            width: 100%;
                        }
                    }

                    .raginglist {
                        // position: absolute;
                        // bottom: 0;
                    }
                }
            }
        }


    }
}

@media (max-width: 767px) {
    .p-solutions-page {
      overflow: auto;
        .solutions-center-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 32px;
            width: 100%;

            .solutions-center {
                .img-box {
                    position: relative;

                    >span {
                        display: inline-block;
                        font-size: 18px;
                        font-weight: 700;
                        color: #838383;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        padding: 0 0 12px 12px;

                        &:hover {
                            color: #fbd42c;
                        }
                    }

                    >img {
                        width: 100%;
                    }
                }

                .solutions-title {
                    position: relative;

                    .container-title {
                        .title-num {
                            color: #fbd42c;
                            font-size: 18px;
                        }

                        .ani-title-container {
                            border-top: 1px solid #000;
                            padding-left: 32px;
                            position: relative;
                            height: 5px;
                            overflow: hidden;
                            width: 180px;

                            .ani-bottom-division {
                                position: absolute;
                                left: 20px;
                                height: 5px;
                                width: 60%;
                                background: #000;
                                transition: all .5s ease-in-out;
                            }
                        }

                        &:hover .ani-title-container .ani-bottom-division {
                            width: 100%;
                        }
                    }

                    .raginglist {
                        margin-top: 24px;
                        margin-bottom: 24px;
                    }
                }
            }
        }


    }
}