
@media (min-width: 768px) {
    .p-roadmap{
        position: relative;
        @keyframes border-data-v-5b3e0953 {
            0% {
              width: 0;
              height: 0;
              top: 50%;
              left: 50%;
              opacity: 0;
            }
        
            50% {
              opacity: 0.55;
            }
        
            100% {
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              opacity: 0;
            }
          }
        .roadmap-centent{
            .time-wraper{
                position: absolute;
                // margin: 272px 0 250px;
                height: 6px;
                width: 100%;
                left: 50%;
                top: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                background:linear-gradient(-90deg,rgba(255,255,255,0),rgba(251,212,44,0.13),rgba(251,212,44,0.99),rgba(251,212,44,0.11),rgba(255,255,255,0));
                .time-item0{
                    left: 10%;
                }
                .time-item1{
                    left: 30%;
                }
                .time-item2{
                    left: 50%;
                }
                .time-item3{
                    left: 70%;
                }
                .time-item{
                    position: absolute;
                    top: -8px;
                    width: 20px;
                    height: 20px;
                    background: #fbd42c;
                    border-radius: 50%;
                    cursor: pointer;
                    .is-active{
                        opacity: 1 !important;
                        font-size: 24px !important;
                        >p{
                            font-size: 16px !important;
                            text-align: left;
                            display: block;
                            line-height: 30px;
                            font-weight: 700;
                        }
                    }
                    .item-content{
                        width: 25vw;
                        position: absolute;
                        opacity: 0.4;
                        -webkit-transition: opacity 1s 0.3s;
                        transition: opacity 1s 0.3s;
                        // width: 320px;
                        >h3{
                            text-align: left;
                            font-size: 24px;
                            margin: 0;
                            padding: 0;
                        }
                        >p{
                            font-size: 12px;
                            text-align: left;
                            display: block;
                            line-height: 30px;
                        }
                    }
                }
                .even{
                    .item-content {
                        bottom: 20px;
                    }
                } 
                .odd{
                    .item-content {
                        top: 30px;
                    }
                }

                .circle-d{
                    width: 92px;
                    height: 92px;
                    position: relative;
                    top: -44px;
                    -webkit-transition: left 1s;
                    transition: left 1s;
                    &:after{
                        content: '';
                        width: 20px;
                        height: 20px;
                        background: #fbd42c;
                        border-radius: 50%;
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        -webkit-transform: translate(-50%, -50%);
                    }
                    span{
                        position: absolute;
                        opacity: 0;
                        border-radius: 50%;
                        border: 0.5px solid rgba(251, 212, 44, 1);
                        opacity: 0.2;
                        animation: border-data-v-5b3e0953 2s linear infinite;
                        -webkit-animation: border-data-v-5b3e0953 2s linear infinite;
                    }
                    
                    span:first-child {
                        -webkit-animation-delay: 0s;
                        animation-delay: 0s;
                    }
                    
                    span:nth-child(2) {
                        -webkit-animation-delay: 1s;
                        animation-delay: 1s;
                    }
                    
                    span:nth-child(3) {
                        -webkit-animation-delay: 2s;
                        animation-delay: 2s;
                    }
                    
                    span:nth-child(4) {
                        -webkit-animation-delay: 3s;
                        animation-delay: 3s;
                    }
                    
                    span:nth-child(5) {
                        -webkit-animation-delay: 4s;
                        animation-delay: 4s;
                    }
                    
                    span:nth-child(6) {
                        -webkit-animation-delay: 5s;
                        animation-delay: 5s;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .p-roadmap{
        position: relative;
        background-color: #1f1f1e;
        .roadmap-centent{
            .centent-mobile{
                .card-box{
                    background-image: url("../../../style/images/home/box_bg.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 100% 100%;
                    height: 300px;
                    color: #fff;
                    padding-top: 50px;
                    .title{
                        height: 200px;
                        line-height:17px;
                        font-size: 12px;
                        overflow-y: auto;
                        box-sizing: border-box;
                        padding: 12px;
                        .centent_box{
                            padding: 12px 30px 0px 35px;
                            .yuan{
                                color: #fbd42c;
                            }
                        }
                        >h3{
                            font-size: 24px;
                            color: #fbd528;
                            margin: 0;
                        }
                    }
                }
                .select{
                    background-image: url("../../../style/images/home/line.png");
                    height: 2px;
                    .select-item{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        transform: translateY(1.8vw);
                        padding-left: 12px;
                        line-height: 12px;
                        font-size: 12px;
                        font-weight: 700;
                        color: #fbd528;
                        .img-active{
                            transform: scale(.7);
                        }
                    }
                }
            }
        }
    }
}