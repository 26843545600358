@media (min-width: 768px) {
    .p-home {
        .swiper-container {
            .swiper-slide {
                height: 100%;
                width: 100%;
                position: relative;
                overflow: hidden;
            }

            .swiper-pagination {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-right: 2%;
                height: 300px;
            }

            .swiper-pagination-bullet {
                width: 10px;
                height: 10px;
                margin-bottom: 26px !important;
                opacity: 0.3;
                background: #cdcdcd;
            }

            .swiper-pagination-bullet:hover {
                opacity: 0.6;
                width: 14px;
                height: 14px;
                margin-bottom: 22px !important;
            }

            .swiper-pagination-bullet-active {
                border: 0px solid #fff;
                border-radius: 50%;
                background-color: #a2a2a2;
                width: 14px;
                height: 14px;
                opacity: 0.6;
            }

            .my-swiper-button-next {
                box-sizing: border-box;
                margin: 0 2% 0% 0 !important;
                background-size: 25px 18px;
                width: 25px;
                height: 18px;
                top: calc(100% - 51px);
                font-size: 44px;
            }

            .swiper-button-next:after {
                display: none;
            }

            .my-swiper-button-next {
                animation: arrowToDown 0.8s ease-in-out infinite;
            }
        }
    }

}

@media (max-width: 767px) {
    .p-home {
        .swiper-container {
            .swiper-slide {
                height: 100%;
                width: 100%;
                position: relative;
                overflow: hidden;
            }

            .swiper-pagination {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-right: 2%;
                height: 300px;
            }

            .swiper-pagination-bullet {
                width: 10px;
                height: 10px;
                margin-bottom: 26px !important;
                opacity: 0.3;
                background: #cdcdcd;
            }

            .swiper-pagination-bullet:hover {
                opacity: 0.6;
                width: 14px;
                height: 14px;
                margin-bottom: 22px !important;
            }

            .swiper-pagination-bullet-active {
                border: 0px solid #fff;
                border-radius: 50%;
                background-color: #a2a2a2;
                width: 14px;
                height: 14px;
                opacity: 0.6;
            }

            .my-swiper-button-next {
                box-sizing: border-box;
                margin: 0 2% 0% 0 !important;
                background-size: 25px 18px;
                width: 50px;
                height: 18px;
                top: calc(100% - 51px);
                font-size: 24px;
            }

            .swiper-button-next:after {
                display: none;
            }

            .my-swiper-button-next {
                animation: arrowToDown 0.8s ease-in-out infinite;
            }
        }
    }
}