.ban-ip{
  width: 100%;
  height: 100vh;
  background-color: #232323;
  display: flex;
  align-items: center;
  justify-content: center;
  .ban-img{
    width: 100%;
  }
  .icon-img{
    position: fixed;
    left: 40px;
    top:40px
  }
}

@media (max-width: 768px){

  .ban-ip{
    overflow:hidden;
    .ban-img{
      width: 300%;
    }
  }

}