@media (min-width: 768px) {
    .p-banner {
        box-sizing: border-box;
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url("../../../style/images/home/bg.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        .title {
            height: calc(100vh - 125px);
            justify-content: flex-end;

            // .line {
            //     display: inline-block;
            //     height: 1px;
            //     width: 50px;
            //     vertical-align: middle;
            // }
            .title-logo{
              display: inline-block;
              margin-right: 12px;
              width: 38px;
              height: 23.68px;
              background: url("../../../style/images/home/img-DeFiCredit.png") center center no-repeat;
              background-size: cover;
            }
            .title-name {
                font-size: 34px;
                margin-bottom: 20px;
                font-weight: 700;
            }
            .title_gap{
              height: 0px;
              width: 50px;
              background-color: #fed000;
              margin: 0 auto;
              margin-bottom: 24px;
              border-bottom: 4px solid;
              border-image-source: linear-gradient(90.05deg, #FEE168 -0.17%, #FEBB0D 99.92%);


            }
            .line-name {
                font-weight: 700;
                line-height: 17px;
                background-size: cover;
                background: url("../../../style/images/home/img-Web3.0.png") center center no-repeat;
                font-size: 14px;
                background-size: 50% 50%;
            }

            .btn {
                text-align: center;
                border-radius: 50px;
                background: linear-gradient(90deg, #fee574 5%, #fed000);
                transform: translateY(20px);
                cursor: pointer;
            }
            .title-btn{
              background: linear-gradient(90.19deg, #FEE168 0%, #FEBB0D 99.83%);
              border-radius: 50px;
              font-size: 24px;
              font-weight: 700;
            }
        }

        .player {
            position: absolute;
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            z-index: -999;
            object-fit: fill;
            opacity: 1;
            transition: all 2s linear;
        }
    }
}

@media (max-width: 767px) {
    .p-banner {
        box-sizing: border-box;
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url("../../../style/images/home/mobile_bg.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        .title {
          height: calc(100vh - 10px);
          justify-content: center;
          align-items: center;
          // .line {
          //   display: inline-block;
          //   height: 1px;
          //   width: 20px;
          //   vertical-align: middle;
          // }
          .title-logo{
            display: inline-block;
            margin-right: 12px;
            width: 24px;
            height: 14.96px;
            background: url("../../../style/images/home/img-DeFiCredit.png") center center no-repeat;
            background-size: cover;
          }
          .title-name {
            font-size: 24px;
            margin-bottom: 28px;
            font-weight: 700;
          }
          .title_gap{
            height: 0px;
            width: 50px;
            background-color: #fed000;
            margin: 0 auto;
            margin-bottom: 24px;
            border-bottom: 4px solid;
            border-image-source: linear-gradient(90.05deg, #FEE168 -0.17%, #FEBB0D 99.92%);


          }
          .line-name {
            font-size: 12px;
            font-weight: 700;
            line-height: 17px;
          }
          .title-btn{
            background: linear-gradient(90.19deg, #FEE168 0%, #FEBB0D 99.83%);
            border-radius: 50px;
            font-size: 18px;
            font-weight: 700;
          }
        }
        .player {
          position: absolute;
          top: 0;
          left: 0;
          width: 100vh;
          // height: 100vw;
          z-index: -999;
          object-fit: fill;
          opacity: 1;
          transition: all 2s linear;
        }
      }
}