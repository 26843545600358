@media (min-width: 768px) {
    .p-case-page {
        position: relative;
        height: 100%;

        .case-center-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: calc(100vh - 160px);
            padding: 0 32px;

            .case-center {
                display: flex;
                width: 100%;
                justify-content: center;

                .logo-img {
                    position: absolute;
                    left: 50px;
                    bottom: 52px;

                }

                .img-content {
                    // position: relative;
                    width: 500px;

                    >img {
                        width: 100%;
                    }
                }

                .content-right {
                    position: relative;
                    margin-left: 30px;

                    .content-text {
                        width: 433px;

                        .title-num {
                            color: #fbd42c;
                            font-size: 24px;
                        }

                        .title {
                            font-weight: 700;
                            margin-top: 30px;
                        }

                        .ani-title-container {
                            border-top: 1px solid #000;
                            padding-left: 32px;
                            position: relative;
                            height: 10px;
                            overflow: hidden;
                            width: 180px;

                            .ani-bottom-division {
                                position: absolute;
                                left: 20px;
                                height: 5px;
                                width: 60%;
                                background: #000;
                                transition: all .5s ease-in-out;
                            }
                        }

                        &:hover .ani-title-container .ani-bottom-division {
                            width: 100%;
                        }
                    }

                    .raginglist {
                        // position: absolute;
                        // bottom: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .p-case-page {
        position: relative;
        height: 100%;
        overflow: auto;
        .case-center-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 32px;

            .case-center {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;

                // padding: 32px;
                .logo-img {
                    position: absolute;
                    left: 10vw;
                    bottom: 10vh;

                }

                .img-content {
                    position: relative;
                    order: 1;

                    >img {
                        width: 100%;
                        height: 50%;
                    }

                }

                .content-right {
                    font-size: 14px;
                    margin-bottom: 12px;

                    // position: relative;
                    // margin-left: 50px;
                    .content-text {

                        .title-num {
                            color: #fbd42c;
                            font-size: 24px;
                        }

                        .title {
                            font-weight: 700;
                            margin-top: 30px;
                        }

                        .ani-title-container {
                            border-top: 1px solid #000;
                            padding-left: 32px;
                            position: relative;
                            height: 10px;
                            overflow: hidden;
                            width: 180px;

                            .ani-bottom-division {
                                position: absolute;
                                left: 20px;
                                height: 5px;
                                width: 60%;
                                background: #000;
                                transition: all .5s ease-in-out;
                            }
                        }

                        &:hover .ani-title-container .ani-bottom-division {
                            width: 100%;
                        }
                    }

                    .raginglist {
                        margin-top: 12px;
                        // position: absolute;
                    }
                }
            }
        }
    }
}